<template>
  <v-chip :color="color">
    <span style="margin-right: 5px" v-if="title">
      Всего
    </span>
    <v-icon v-else-if="icon" size="20" style="margin-right: 5px" color="rgba(0,0,0,.6)">mdi-{{ icon }}</v-icon>
    <span :style="`color: ${getColor(value)}`">{{ $root.printCost(value, 1) }}</span>
  </v-chip>
</template>
<script>

export default {
  name: "SumChip",
  props: {
    value: {default: '0'},
    icon: {default: ''},
    title: {default: ''},
    color: {default: 'transparent'},
  },
  methods: {
    getColor(val) {
      val = parseInt(val)
      if (!val) return'';
      return val>=0?'green' : 'red'
    }
  }
}
</script>
